import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../../hooks/useSiteMetadata"

interface SEOInterface {
  lang?: string
  meta?: string[]
  title?: string
  keywords?: string[]
  description?: string
  image?: string
}

const SEO = ({
  lang = `en`,
  title,
  description,
  keywords,
  image,
}: SEOInterface) => {
  const siteMetadata = useSiteMetadata()
  const metaDescription = description || siteMetadata.description
  const metaTitle = title || siteMetadata.title
  const metaKeywords = keywords || siteMetadata.keywords
  const metaImage = image || siteMetadata.image

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="og:title" content={metaTitle} />
      <meta name="og:type" content="website" />
      <meta name="og:image" content={metaImage} />
      <meta name="description" content={metaDescription} />
      <meta name="og:description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords && metaKeywords.join(", ")} />
    </Helmet>
  )
}

export default SEO
