import { useStaticQuery, graphql } from "gatsby"
import { Query, SiteSiteMetadata } from "graphql-types"

const useSiteMetadata = (): SiteSiteMetadata => {
  const { site }: Query = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            copyright
            siteUrl
            title
            description
            keywords
            image
          }
        }
      }
    `
  )

  return site.siteMetadata
}

export default useSiteMetadata
